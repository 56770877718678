import { RoutesPaths } from '@Balance/RoutesPaths'
import { roles } from '@Balance/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input, InputSize } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { API } from '@Services/api'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { TablePagination } from '@material-ui/core'
import { Form } from '@unform/web'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NewSupplierModal } from '../Common/NewSupplierModal/NewSupplierModal'
import { useSupplier } from '../Hooks/useSupplier'

export const SupplierList = () => {
    const [isNewSupplierModalOpen, setIsNewSupplierModalOpen] = useState(false)
    const { loading, supplier, search } = useSupplier(API)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const navigate = useNavigate()

    const toggleModal = () => setIsNewSupplierModalOpen(!isNewSupplierModalOpen)
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        search(e.target.value)
    }
    const handleNavigate = (id: string | number) => () => {
        navigate(formatRouteId(RoutesPaths.SupplierDetail, id))
    }

    const getPaginateSupplier = (page: number, limit: number) => {
        return supplier.slice((page - 1) * limit, page * limit)
    }

    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Fornecedores</h1>
                <Form onSubmit={() => {}}>
                    <Grid container>
                        <Grid item lg={6}>
                            <></>
                        </Grid>
                        <Grid item lg={6} flex flexEnd>
                            {Boolean(supplier.length) && (
                                <Input
                                    name="search"
                                    size={InputSize.Small}
                                    label="Buscar"
                                    onChange={handleSearch}
                                />
                            )}
                            <PermissionWrapper
                                permission={
                                    roles.suppliers.permissions.create.key
                                }
                            >
                                <Button
                                    onClick={toggleModal}
                                    variant={ButtonVariant.primary}
                                >
                                    Novo Fornecedor
                                </Button>
                            </PermissionWrapper>
                        </Grid>
                    </Grid>
                    <NewSupplierModal
                        isOpen={isNewSupplierModalOpen}
                        onClose={toggleModal}
                    />
                </Form>
            </ContainerHeader>
            {loading && <Loader />}
            <Table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        supplier &&
                        getPaginateSupplier(page, limit).map((supplier) => (
                            <tr
                                key={supplier._id}
                                data-clickable
                                onClick={handleNavigate(supplier._id)}
                            >
                                <td>{supplier.corporateName}</td>
                                <td>{supplier.mail}</td>
                            </tr>
                        ))}
                    {!loading && !supplier.length && (
                        <tr>
                            <td colSpan={2}>Nenhum fornecedor encontrado</td>
                        </tr>
                    )}
                </tbody>
                <TablePagination
                    labelRowsPerPage="Itens por página"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={supplier.length}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count }) =>
                        from
                            ? `${from}-${to} de ${
                                  count !== -1 ? count : `mais de ${to}`
                              }`
                            : `1 de ${count !== -1 ? count : `mais de ${to}`}`
                    }
                    page={Number(page - 1)}
                    onPageChange={(_, page) => {
                        setPage(page + 1)
                    }}
                    onRowsPerPageChange={(e) => {
                        setLimit(Number(e.target.value))
                    }}
                />
            </Table>
        </Container>
    )
}
