import { Table } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { DBInventoryExits } from '@InventoryControl/Exits/Data/DataSource/API/Entity/Exits'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { formatCurrency } from '@Utils/Formatters/currency'
import { TablePagination } from '@material-ui/core'
import { format } from 'date-fns'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const ExitList = ({
    filteredExits,
    loading,
}: {
    filteredExits: DBInventoryExits[]
    loading: boolean
}) => {
    const navigate = useNavigate()
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const getPaginate = (page: number, limit: number) => {
        return filteredExits.slice((page - 1) * limit, page * limit)
    }
    const gotoExit = (id: string) => {
        navigate(RoutesPaths.InventoryExit.replace(':id', id))
    }

    if (loading) return <Loader />

    return (
        <Grid>
            <Table>
                <thead>
                    <tr>
                        <th>Produto</th>
                        <th>Destino</th>
                        <th>Quantidade</th>
                        <th>Custo Unitário</th>
                        <th>Valor de Venda Total</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {getPaginate(page, limit).map((exit) => (
                        <tr
                            key={exit._id}
                            data-clickable
                            onClick={() => gotoExit(exit._id)}
                        >
                            <td>{exit.product?.name ?? 'N/D'}</td>
                            <td>
                                {exit?.client
                                    ? `Cliente: ${exit?.client?.corporateName}`
                                    : exit.destination?.name ?? 'N/D'}
                            </td>
                            <td>{exit.quantity ?? 'N/D'}</td>
                            <td>{formatCurrency(exit.unitCost) ?? 'N/D'}</td>
                            <td>
                                {formatCurrency(
                                    Number(exit.totalSalesValue || 0)
                                ) ?? 'N/D'}
                            </td>
                            <td>
                                {format(
                                    new Date(exit?.created_at),
                                    'dd/MM/yyyy'
                                ) ?? 'N/D'}
                            </td>
                        </tr>
                    ))}
                    {filteredExits.length === 0 && (
                        <tr>
                            <td colSpan={9}>Nenhum registro encontrado</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {Number(filteredExits?.length) > 10 && (
                <TablePagination
                    labelRowsPerPage="Itens por página"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredExits.length}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count }) =>
                        from ? `${from}-${to} de ${count}` : `1 de ${count}`
                    }
                    page={Number(page - 1)}
                    onPageChange={(_, page) => setPage(page + 1)}
                    onRowsPerPageChange={(e) => {
                        setLimit(Number(e.target.value))
                    }}
                />
            )}
        </Grid>
    )
}
