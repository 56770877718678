import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { useCommercialContext } from '@Commercial/Context/CommercialContextProvider'
import { RoutesPaths } from '@Commercial/RoutesPaths'
import { roles } from '@Commercial/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { formatCurrency } from '@Utils/Formatters/currency'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { Grid, TablePagination, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { AiOutlineMore } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ProposalFilter } from '../Components/ProposalFilter/ProposalFilter'
import { StatusTag } from '../Components/StatusTag/StatusTag'
import { DBCommercialProposal } from '../Data/DataSource/API/Entity/Proposal'
import {
    CommercialProposalContainer,
    CommercialProposalRegister,
} from '../Data/DataSource/Container'
import { usePaginate } from '../Hooks/usePaginate/usePaginate'
import { getMarkupStatus } from '../Utils/markupStatus'
import { STATUS } from './ProposalList.contants'

export const ProposalList = () => {
    const navigate = useNavigate()
    const [proposals, setProposals] = useState<DBCommercialProposal[]>([])
    const [loadingDup, setLoadingDup] = useState(false)
    const { allProposals, setAllProposals, getProposal } =
        useCommercialContext()
    const [loading, setLoading] = useState(true)
    const { selectedUser } = useCommercialContext()
    const [date, setDate] = useState<DateRange | undefined>(undefined)

    const { hasPermission } = usePermissions()
    const readCost = hasPermission(
        roles.proposal.permissions.read.permissions.readCost.key
    )
    const viewAll = hasPermission(
        roles.proposal.permissions.read.permissions.all.key
    )

    const proposalContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseDuplicateProposal
    )

    const {
        handleSearch,
        handleStatusChange,
        hasStatus,
        search,
        page,
        limit,
        setPage,
        setLimit,
        filteredProposal,
        getPaginate,
    } = usePaginate({ proposals })

    const gotoNewProposal = () => {
        navigate(RoutesPaths.CommercialCreateProposal)
    }

    const proposalAmount = useMemo(
        () =>
            filteredProposal?.reduce(
                (acc, cur) => Number(acc) + Number(cur?.totalPrice || 0),
                0
            ),
        [filteredProposal]
    )

    const handleFilters = useCallback(() => {
        const newList = allProposals?.filter((item) => {
            const byUser =
                selectedUser?.includes(item?.user?.mid) ||
                selectedUser?.includes('all')

            const byDate =
                (date?.from
                    ? new Date(item?.createdAt).getTime() >=
                      date?.from?.getTime()
                    : true) &&
                (date?.to
                    ? new Date(item?.createdAt).getTime() <= date?.to?.getTime()
                    : true)
            return byUser && byDate
        })
        setProposals(newList)
    }, [allProposals, selectedUser, date])

    const handleDuplicate = useCallback(
        async (id: string) => {
            setLoadingDup(true)
            const data = await proposalContainer.execute(id)
            toast.success('Proposta duplicada com sucesso')
            navigate(formatRouteId(RoutesPaths.CommercialProposal, data._id), {
                state: { duplicate: true },
            })
        },
        [proposalContainer]
    )

    const handleDateChange = useCallback(
        (date: DateRange) => {
            setDate(date)
        },
        [allProposals, selectedUser]
    )

    useEffect(() => {
        handleFilters()
    }, [date, selectedUser, allProposals])

    useEffect(() => {
        setProposals(allProposals)
        setLoading(false)
        setProposals(allProposals)
        setAllProposals(allProposals)
    }, [allProposals])

    useEffect(() => {
        getProposal()
    }, [])

    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item lg={4} md={12} xs={12}>
                        <h1 className="text-2xl font-bold">Propostas</h1>
                    </Grid>
                    <Grid
                        container
                        item
                        lg={8}
                        md={12}
                        xs={12}
                        justifyContent="flex-end"
                        spacing={1}
                    >
                        <RightContent>
                            <Grid item container alignItems="center">
                                <Typography variant="body2" noWrap>
                                    {formatCurrency(proposalAmount as number)} ·{' '}
                                    {filteredProposal.length} propostas
                                </Typography>
                            </Grid>
                            <ProposalFilter
                                viewAll={viewAll}
                                handleSearch={handleSearch}
                                search={search}
                                handleStatusChange={handleStatusChange}
                                hasStatus={hasStatus}
                                handleDateChange={handleDateChange}
                            />
                            <PermissionWrapper
                                permission={
                                    roles.proposal.permissions.create.key
                                }
                            >
                                <Grid container alignItems="center">
                                    <Button
                                        onClick={gotoNewProposal}
                                        variant={ButtonVariant.primary}
                                        color="primary"
                                    >
                                        Nova Proposta
                                    </Button>
                                </Grid>
                            </PermissionWrapper>
                        </RightContent>
                    </Grid>
                </Grid>
            </ContainerHeader>
            <Table>
                <thead>
                    <tr>
                        <th>Pipe</th>
                        <th>Cliente</th>
                        {readCost && <th>Custo</th>}
                        <th>Venda</th>
                        <th>Markup</th>
                        {readCost && <th>Lucro</th>}
                        <th>Vendedor</th>
                        <th>Data</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {getPaginate(page, limit).map((item) => (
                        <tr
                            key={item._id}
                            data-clickable
                            onClick={() =>
                                navigate(
                                    formatRouteId(
                                        RoutesPaths.CommercialProposal,
                                        item._id
                                    )
                                )
                            }
                        >
                            <td>{(item.name || '').toUpperCase()}</td>
                            <td>{item.clientName || 'N/A'}</td>
                            {readCost && (
                                <td>{formatCurrency(item.costTotal)}</td>
                            )}
                            <td>{formatCurrency(item.totalPrice)}</td>
                            <td
                                className="opacity"
                                data-status={getMarkupStatus(item)}
                            >
                                {Math.floor(item.markup)}%
                            </td>
                            {readCost && <td>{formatCurrency(item.profit)}</td>}
                            <td>{item?.user?.name ?? 'N/D'}</td>
                            <td>
                                {format(new Date(item.createdAt), 'dd/MM/yyyy')}
                            </td>
                            <td>
                                {item?.status && STATUS[item?.status] ? (
                                    <StatusTag
                                        title="Atualizado automaticamente"
                                        status={item.status}
                                    >
                                        {STATUS[item?.status]}
                                    </StatusTag>
                                ) : (
                                    <StatusTag
                                        title="Aguardando atualização"
                                        status="default"
                                    >
                                        N/D
                                    </StatusTag>
                                )}
                            </td>
                            <th onClick={(e) => e.preventDefault()}>
                                <DropdownMenu>
                                    <DropdownMenuTrigger
                                        className="p-2 w-[100%]"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <AiOutlineMore />
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent>
                                        <DropdownMenuItem
                                            disabled={loadingDup}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                handleDuplicate(item._id)
                                            }}
                                        >
                                            {loadingDup
                                                ? 'Aguarde...'
                                                : 'Duplicar'}
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </th>
                        </tr>
                    ))}
                    {!loading && !getPaginate(page, limit).length && (
                        <tr>
                            <td colSpan={readCost ? 10 : 8}>
                                <h3>Nenhuma proposta encontrada</h3>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {Number(filteredProposal?.length) > 10 && (
                <TablePagination
                    labelRowsPerPage="Itens por página"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredProposal.length}
                    rowsPerPage={limit}
                    labelDisplayedRows={({ from, to, count }) =>
                        from ? `${from}-${to} de ${count}` : `1 de ${count}`
                    }
                    page={Number(page - 1)}
                    onPageChange={(_, page) => setPage(page + 1)}
                    onRowsPerPageChange={(e) => {
                        setLimit(Number(e.target.value))
                    }}
                />
            )}
            {loading && <Loader />}
        </Container>
    )
}
