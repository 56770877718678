import { RoutesPaths } from '@Balance/RoutesPaths'
import { roles } from '@Balance/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { Grid, TablePagination } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane'
import { useEffect, useState } from 'react'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import { BsLink45Deg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import { IgnorePreMovementModal } from '../Common/IgnorePreMovementModal/IgnorePreMovementModal'
import { WhatsIsPreMovementsBottomSheet } from '../Common/WhatsIsPreMovementsBottomSheet/WhatsIsPreMovementsBottomSheet'
import { usePaginate } from '../Hooks/usePaginate'
import { usePreMovements } from '../Hooks/usePreMovements'

export const PreMovementsList = () => {
    const navigate = useNavigate()
    const { movements, loading, getPreMovements } = usePreMovements({})
    const { entities, getPaginate, page, limit, setPage, setLimit } =
        usePaginate({ entities: movements })
    const [showIgnoreModal, setShowIgnoreModal] = useState(false)
    const { hasPermission } = usePermissions()
    const [selectedPreMovement, setSelectedPreMovement] = useState<any>()

    let settings: CupertinoSettings = {
        backdrop: true,
        fitHeight: true,
        bottomClose: true,
        fastSwipeClose: true,
    }
    let myPane = new CupertinoPane('.cupertino-pane', settings)

    const openNewMovement = (item: any) => () => {
        navigate(RoutesPaths.NewMovement, {
            state: { preMovement: item },
        })
    }

    const openIgnoreMovement = (item: any) => () => {
        setShowIgnoreModal(true)
        setSelectedPreMovement(item)
    }

    const onCloseIgnoreModal = () => {
        setShowIgnoreModal(false)
        getPreMovements()
    }

    useEffect(() => {
        getPreMovements()
    }, [])
    return (
        <Container>
            <ContainerHeader>
                <h1 className="text-2xl font-bold">
                    Pre-Movimentações{' '}
                    <AiOutlineQuestionCircle
                        className="clickable"
                        onClick={() => myPane.present({ animate: true })}
                        size={20}
                        title="O que é pre movimentações?"
                    />
                </h1>
            </ContainerHeader>
            <div className="cupertino-pane">
                <WhatsIsPreMovementsBottomSheet />
            </div>
            <IgnorePreMovementModal
                isOpen={showIgnoreModal}
                onClose={onCloseIgnoreModal}
                item={selectedPreMovement}
            />
            <Alert severity="warning">
                <AlertTitle>
                    <b>Atenção!</b>
                </AlertTitle>
                <span>
                    Todas as movimentações listadas aqui devem ser conferidas
                    antes de prosseguir
                </span>
            </Alert>
            <Grid container item lg={12}>
                <Table>
                    <thead>
                        <th>Ticket</th>
                        <th>Produto</th>
                        <th>Quantidade</th>
                        <th>Cliente</th>
                        <th>Fornecedor</th>
                    </thead>
                    <tbody>
                        {entities &&
                            getPaginate(page, limit).map((item) => {
                                const {
                                    id,
                                    product,
                                    ticket,
                                    quantity,
                                    client,
                                    supplier,
                                } = item
                                return (
                                    <tr key={id}>
                                        <td>
                                            <a
                                                title="Abrir ticket no Tiflux"
                                                target="_blank"
                                                href={`https://app.tiflux.com/v/tickets/${ticket.ticketNumber}/basic_info`}
                                            >
                                                <span>
                                                    {ticket.ticketNumber}{' '}
                                                </span>
                                                <i>
                                                    <BsLink45Deg />
                                                </i>
                                            </a>
                                        </td>
                                        <td>{product}</td>
                                        <td>{quantity}</td>
                                        <td>{client}</td>
                                        <td>{supplier}</td>
                                        {hasPermission(
                                            roles.preMovements.permissions
                                                .create.key
                                        ) && (
                                            <td>
                                                <Button
                                                    size="small"
                                                    variant={
                                                        ButtonVariant.primary
                                                    }
                                                    onClick={openNewMovement(
                                                        item
                                                    )}
                                                >
                                                    Conferir
                                                </Button>
                                            </td>
                                        )}
                                        {hasPermission(
                                            roles.preMovements.permissions
                                                .delete.key
                                        ) && (
                                            <td>
                                                <Button
                                                    size="small"
                                                    variant={
                                                        ButtonVariant.secondary
                                                    }
                                                    onClick={openIgnoreMovement(
                                                        item
                                                    )}
                                                >
                                                    Ignorar
                                                </Button>
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
                <Grid item lg={12}>
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={entities.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}
