import { DBCommercialProduct } from '@Commercial/Products/Data/DataSource/API/Entity/Product'
import { RoutesPaths } from '@Commercial/RoutesPaths'
import { roles } from '@Commercial/roles'
import { Table } from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Loader } from '@Core/Components/Loader/Loader'
import { usePermissions } from '@Core/Hooks/usePermissions'
import { formatCurrency } from '@Utils/Formatters/currency'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { TablePagination } from '@material-ui/core'
import { Key } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProductsListProps } from './ProductsLis.types'

export const ProductsListComponent = ({
    summary = false,
    products,
    filteredProducts,
    loading,
    getPaginate,
    page,
    limit,
    setLimit,
    setPage,
}: ProductsListProps) => {
    const navigate = useNavigate()

    const { hasPermission } = usePermissions()

    const updateCost = hasPermission(
        roles.product.permissions.update.permissions.cost.key
    )
    const updateMarkup = hasPermission(
        roles.product.permissions.update.permissions.markup.key
    )

    const isDynamic = (entity: DBCommercialProduct) => {
        return entity?.productType !== 'default' && entity?.productType !== undefined
    }

    const colSpanSize = (entity: DBCommercialProduct) => {
        let size = 0
        if (isDynamic(entity)) {
            size += updateCost ? 1 : 0
            size += updateMarkup ? 3 : 0
        }
        return size
    }

    return (
        <Grid container item lg={12}>
            <Grid item lg={12}>
                {loading && <Loader />}
                {!loading && products && (
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                {updateCost && <th>Custo Unitário</th>}
                                {updateMarkup && (
                                    <>
                                        <th>Markup Mínimo</th>
                                        <th>Markup Ideal</th>
                                        <th>Markup Máximo</th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {getPaginate(page, limit).map((product) => (
                                <tr
                                    key={product._id as Key}
                                    data-inactive={product.isInactive}
                                    data-clickable={!summary}
                                    onClick={() => {
                                        navigate(
                                            formatRouteId(
                                                RoutesPaths.CommercialProduct,
                                                product._id
                                            )
                                        )
                                    }}
                                >
                                    <td>
                                        {(product.name || '').toUpperCase()}
                                    </td>
                                    {isDynamic(product) && (
                                        <td colSpan={colSpanSize(product)}></td>
                                    )}

                                    {!isDynamic(product) && updateCost && (
                                        <td>
                                            {formatCurrency(
                                                product.unitCost || 0
                                            )}
                                        </td>
                                    )}
                                    {!isDynamic(product) && updateMarkup && (
                                        <>
                                            <td>{product.minMarkup}</td>
                                            <td>{product.markup}</td>
                                            <td>{product.maxMarkup}</td>
                                        </>
                                    )}
                                </tr>
                            ))}
                            {!getPaginate(page, limit).length && (
                                <tr>
                                    <td colSpan={3}>
                                        Nenhum produto encontrado
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
                {Number(filteredProducts?.length) > 10 && (
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={filteredProducts.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                )}
            </Grid>
        </Grid>
    )
}
