import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { BackButton } from '@Core/Components/BackButton/BackButton'
import { Input } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { MenuOptions } from '@Core/Components/MenuOptions/MenuOptions'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { RoutesPaths } from '@InventoryControl/RoutesPaths'
import { roles } from '@InventoryControl/roles'
import { formatCurrency } from '@Utils/Formatters/currency'
import { formatRouteId } from '@Utils/Formatters/formatRouteId'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { Grid, TablePagination } from '@material-ui/core'
import { Key, useEffect, useState } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { NewSupplierModal } from '../Common/NewSupplierModal/NewSupplierModal'
import { useInventorySuppliers } from '../Hooks/useInventorySupplier/useInventorySupplier'
import {
    exportInventoryDictionary,
    exportInventorySupplierValueDictionary,
    exportSupplierData,
} from '../Utils/excelExport'

export const SuppliersList = () => {
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { suppliers, getSuppliers, loading } = useInventorySuppliers()
    const [searchTest, setSearchTest] = useState('')
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTest(event.target.value)
    }
    const filteredSuppliers = suppliers.filter((supplier) =>
        supplier.name.toLowerCase().includes(searchTest.toLowerCase())
    )

    const getPaginate = (page: number, limit: number) => {
        return filteredSuppliers.slice((page - 1) * limit, page * limit)
    }

    const openModal = () => {
        setIsModalOpen(true)
    }

    const exportMenuList: IMenuOption[] = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(
                    exportSupplierData(filteredSuppliers),
                    `Fornecedores`,
                    {
                        columnsDict: exportInventoryDictionary,
                        valuesDict: exportInventorySupplierValueDictionary,
                        sheetName: 'Fornecedores',
                    }
                )
            },
        },
    ]

    useEffect(() => {
        getSuppliers()
    }, [])
    return (
        <Container>
            <BackButton />
            <ContainerHeader>
                <h1 className="text-2xl font-bold">Fornecedores</h1>
                <RightContent>
                    <Input
                        useUnform={false}
                        name="search"
                        label="Buscar"
                        size="small"
                        onChange={handleSearch}
                        value={searchTest}
                    />
                    <PermissionWrapper
                        permission={roles.suppliers.permissions.create.key}
                    >
                        <Button
                            onClick={openModal}
                            variant={ButtonVariant.primary}
                            color="primary"
                        >
                            Novo Fornecedor
                        </Button>
                        {!loading && (
                            <NewSupplierModal
                                isOpen={isModalOpen}
                                setIsOpen={setIsModalOpen}
                                callback={getSuppliers}
                            />
                        )}
                    </PermissionWrapper>
                    <PermissionWrapper
                        permission={roles.suppliers.permissions.export.key}
                    >
                        <MenuOptions menuList={exportMenuList}>
                            <TfiExport />
                        </MenuOptions>
                    </PermissionWrapper>
                </RightContent>
            </ContainerHeader>
            <Grid container item xs={12} lg={12}>
                <Grid item xs={12} lg={12}>
                    {loading && <Loader />}
                    {!loading && suppliers && (
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Telefone</th>
                                    <th>E-mail</th>
                                    <th>Contato</th>
                                    <th>Total Comprado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getPaginate(page, limit).map((supplier) => (
                                    <tr
                                        key={supplier._id as Key}
                                        data-clickable
                                        onClick={() => {
                                            navigate(
                                                formatRouteId(
                                                    RoutesPaths.InventorySupplier,
                                                    supplier._id
                                                )
                                            )
                                        }}
                                    >
                                        <td>{supplier.name}</td>
                                        <td>{supplier.phone}</td>
                                        <td>{supplier.email}</td>
                                        <td>{supplier.contact}</td>
                                        <td>
                                            {formatCurrency(
                                                Number(
                                                    supplier.totalPurchases || 0
                                                )
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {!loading && suppliers.length > 10 && (
                        <TablePagination
                            labelRowsPerPage="Itens por página"
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={suppliers.length}
                            rowsPerPage={limit}
                            labelDisplayedRows={({ from, to, count }) =>
                                from
                                    ? `${from}-${to} de ${count}`
                                    : `1 de ${count}`
                            }
                            page={Number(page - 1)}
                            onPageChange={(_, page) => setPage(page + 1)}
                            onRowsPerPageChange={(e) => {
                                setLimit(Number(e.target.value))
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
    )
}
