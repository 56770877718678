import { roles } from '@Balance/roles'
import { RoutesPaths } from '@Balance/RoutesPaths'
import {
    exportClientMovementValueDictionary,
    exportClientStockDictionary,
} from '@Clients/ClientDetails/ClientDetails.utils'
import { useClients } from '@Clients/Hooks/useClients'
import { RightContent } from '@Entry/Common/Header/Header.styles'
import { Grid, Paper, TablePagination } from '@material-ui/core'
import { DBMovement } from '@Movements/Data/DataSource/API/Entity/Movement'
import {
    dateFormatter,
    getMovementType,
} from '@Movements/Utils/Movements.utils'
import { useProducts } from '@Products/Hooks/useProducts'
import { usePDF } from '@react-pdf/renderer'
import { MovementDocument } from '@Reports/Pdf/Pdf'
import { API } from '@Services/api'
import { exportXlsx } from '@Utils/Support/exportXlsx'
import { useEffect, useMemo, useState } from 'react'
import { TfiExport } from 'react-icons/tfi'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box } from '../Box/Box'
import { Loader } from '../Loader/Loader'
import { MenuOptions } from '../MenuOptions/MenuOptions'
import { IMenuOption } from '../MenuOptions/MenuOptions.types'
import { PermissionWrapper } from '../PermissionWrapper/PermissionWrapper'
import { Table } from '../Table/Table'
import { ClientFilter } from './components/ClientFilter/ClientFilter'
import { DateFilter } from './components/DateFilter/DateFilter'
import { ProductFilter } from './components/ProductFilter/ProductFilter'
import { MovementsTableProps } from './MovementsTable.types'
import {
    exportMovementData,
    filterByClient,
    filterByDate,
    filterByProduct,
    hasFilter,
} from './MovementsTable.utils'

export const MovementsTable = ({
    movements,
    allMovements,
    disableFilters,
    hideFilters,
    options,
    hasPagination,
    getMovements,
    loading,
    hideColumns = [],
}: MovementsTableProps) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { products, getProducts } = useProducts(API)
    const { clients } = useClients()
    const [selectedProduct, setSelectedProduct] = useState('')
    const [selectedClient, setSelectedClient] = useState('')
    const [selectedRange, setSelectedRange] = useState<any>(['', ''])
    const [limit, setLimit] = useState(10)

    const filtredMovements: DBMovement[] = useMemo(() => {
        const movementsToFilter = hasFilter(
            selectedRange,
            selectedProduct,
            selectedClient
        )
            ? allMovements
            : movements
        return (movementsToFilter || [])
            .filter(filterByProduct(selectedProduct))
            .filter(filterByDate(selectedRange))
            .filter(filterByClient(selectedClient))
    }, [
        allMovements,
        movements,
        filterByProduct,
        filterByDate,
        selectedProduct,
        selectedRange,
        selectedClient,
    ])

    const documentMemo = useMemo(() => MovementDocument({
        title: `Movimentações`,
        movements: filtredMovements,
    }), [filtredMovements, MovementDocument])
    const [instance, update] = usePDF({
        document: documentMemo,
    })
    const exportMovementMenuList: IMenuOption[] = [
        {
            label: 'Exportar para Excel',
            onClick: () => {
                exportXlsx(
                    exportMovementData(filtredMovements),
                    `Movimentações`,
                    {
                        columnsDict: exportClientStockDictionary,
                        valuesDict: exportClientMovementValueDictionary,
                        sheetName: 'Movimentações',
                    }
                )
            },
        },
        {
            label: 'Exportar para PDF',
            // @ts-ignore
            href: instance?.url,
            attrs: {
                target: '_blank',
                download: 'Movimentações.pdf',
            },
        },
    ]

    useEffect(() => {
        update(documentMemo)
    }, [filtredMovements, documentMemo])

    useEffect(() => {
        getProducts()
        update(documentMemo)
    }, [])

    if (loading) {
        return <Loader />
    }
    return (
        <PermissionWrapper permission={roles.movements.permissions.read.key}>
            <Grid container item lg={12}>
                {!disableFilters && (
                    <Grid container item lg={12}>
                        <Paper style={{ width: '100%' }}>
                            <Box p={3}>
                                <Grid container lg={12}>
                                    <Grid item lg={4}>
                                        {!hideFilters?.includes('client') && (
                                            <Box ml={3}>
                                                <ClientFilter
                                                    clients={clients}
                                                    setSelectedClient={
                                                        setSelectedClient
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item lg={4}>
                                        <Box ml={3}>
                                            <ProductFilter
                                                products={products}
                                                setSelectedProduct={
                                                    setSelectedProduct
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Box ml={3}>
                                            <DateFilter
                                                range={selectedRange}
                                                onChange={setSelectedRange}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={2}
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >
                                        <RightContent>
                                            <PermissionWrapper
                                                permission={
                                                    roles.movements.permissions
                                                        .export.key
                                                }
                                            >
                                                {Boolean(movements?.length) && (
                                                    <MenuOptions
                                                        menuList={
                                                            exportMovementMenuList
                                                        }
                                                    >
                                                        <TfiExport />
                                                    </MenuOptions>
                                                )}
                                            </PermissionWrapper>
                                        </RightContent>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                <Grid container item lg={12}>
                    <Table>
                        <thead>
                            <tr>
                                {!hideColumns?.includes('client') && (
                                    <th>Cliente</th>
                                )}
                                {!hideColumns?.includes('supplier') && (
                                    <th>Fornecedor</th>
                                )}
                                <th>Produto</th>
                                <th>Qtd</th>
                                <th>Tipo</th>
                                <th>Responsável</th>
                                <th>Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading &&
                                filtredMovements?.map((movement) => (
                                    <tr
                                        data-clickable
                                        key={movement._id}
                                        onClick={() =>
                                            navigate(
                                                `${RoutesPaths.Movement}/${movement?._id}`,
                                                {
                                                    state: {
                                                        redirectPath: pathname,
                                                    },
                                                }
                                            )
                                        }
                                    >
                                        {!hideColumns?.includes('client') && (
                                            <>
                                                <td>
                                                    {movement.client[0]
                                                        ?.corporateName ??
                                                        'N/A'}
                                                </td>
                                            </>
                                        )}
                                        {!hideColumns?.includes('supplier') && (
                                            <td>
                                                {movement.supplier?.[0]
                                                    ?.corporateName ?? 'N/A'}
                                            </td>
                                        )}
                                        <td>
                                            {movement.product[0]?.name ?? 'N/A'}
                                        </td>
                                        <td>{movement.amount ?? 'N/A'}</td>
                                        <td>
                                            {getMovementType(movement.type) ??
                                                'N/A'}
                                        </td>
                                        <td>{movement.user?.name ?? 'N/A'}</td>
                                        <td>
                                            {dateFormatter(
                                                new Date(movement.date)
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            {!movements?.length && (
                                <tr>
                                    <td colSpan={7}>
                                        Nenhum registro encontrado
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {Number(options?.count) > 10 && hasPagination && (
                        <TablePagination
                            labelRowsPerPage="Itens por página"
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={Number(options?.count)}
                            rowsPerPage={limit}
                            labelDisplayedRows={({ from, to, count }) =>
                                from
                                    ? `${from}-${to} de ${
                                          count !== -1 ? count : `mais de ${to}`
                                      }`
                                    : `1 de ${
                                          count !== -1 ? count : `mais de ${to}`
                                      }`
                            }
                            page={Number(Number(options?.page) - 1)}
                            onPageChange={(_, page) =>
                                getMovements?.(page + 1, limit)
                            }
                            onRowsPerPageChange={(e) => {
                                setLimit(Number(e.target.value))
                                getMovements?.(1, Number(e.target.value))
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </PermissionWrapper>
    )
}
