import { RoutesPaths } from '@Balance/RoutesPaths'
import { roles } from '@Balance/roles'
import {
    Button,
    ButtonVariant,
    Container,
    ContainerHeader,
    Table,
} from '@Core/Components'
import { Grid } from '@Core/Components/Grid/Grid'
import { Input, InputSize } from '@Core/Components/Input/Input'
import { Loader } from '@Core/Components/Loader/Loader'
import { PermissionWrapper } from '@Core/Components/PermissionWrapper/PermissionWrapper'
import { TablePagination } from '@material-ui/core'
import { Form } from '@unform/web'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NewClientModal } from '../Common/NewClientModal/NewClientModal'
import { useClients } from '../Hooks/useClients'

export const ClientsList = () => {
    const navigate = useNavigate()
    const { localClients: clients, localSearch, loading } = useClients()
    const [isOpen, setIsOpen] = useState(false)
    const [limit, setLimit] = useState(
        Number(localStorage.getItem('client-search-limit')) || 10
    )
    const [page, setPage] = useState(
        Number(localStorage.getItem('client-search-page')) || 1
    )
    const [searchText, setSearchText] = useState('')
    const toggleModal = () => setIsOpen(!isOpen)

    const handleNavigate = (id: string | number) => () => {
        navigate(`${RoutesPaths.Client}${id}`)
    }

    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }

    const getPaginateClients = (page: number, limit: number) => {
        return clients.slice((page - 1) * limit, page * limit)
    }

    const persistentSearch = () => {
        const clientSearch = localStorage.getItem('client-search')
        const pagePersistent = localStorage.getItem('client-search-page')
        const limitPersistent = localStorage.getItem('client-search-limit')
        if (clientSearch) {
            localSearch(clientSearch)
            setSearchText(clientSearch)
            setPage(Number(pagePersistent))
            setLimit(Number(limitPersistent))
        }
    }

    useEffect(() => {
        if (!loading) {
            persistentSearch()
        }
    }, [loading])

    useEffect(() => {
        if (!loading) {
            localStorage.setItem('client-search', searchText)
            localSearch(searchText)
            setPage(1)
        }
    }, [searchText, loading])

    useEffect(() => {
        if (!loading) {
            localStorage.setItem('client-search-page', String(page))
        }
    }, [page, loading])

    useEffect(() => {
        if (!loading) {
            localStorage.setItem('client-search-limit', String(limit))
        }
    }, [limit, loading])

    return (
        <Container>
            <Form onSubmit={() => {}}>
                <ContainerHeader>
                    <h1 className="text-2xl font-bold">Clientes</h1>
                    <Grid container>
                        <Grid item lg={6}>
                            <></>
                        </Grid>
                        <Grid item lg={6} flex flexEnd>
                            <Input
                                name="search"
                                size={InputSize.Small}
                                label="Buscar"
                                onChange={handleSearch}
                                value={searchText}
                            />
                            <PermissionWrapper
                                permission={
                                    roles.clients.permissions.create.key
                                }
                            >
                                <Button
                                    onClick={toggleModal}
                                    variant={ButtonVariant.primary}
                                >
                                    Novo Cliente
                                </Button>
                            </PermissionWrapper>
                        </Grid>
                    </Grid>
                    <NewClientModal isOpen={isOpen} onClose={toggleModal} />
                </ContainerHeader>
            </Form>
            {loading && <Loader />}
            {!loading && clients && (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && !clients.length && (
                                <tr>
                                    <td colSpan={2}>
                                        Nenhum cliente encontrado
                                    </td>
                                </tr>
                            )}
                            {clients &&
                                getPaginateClients(page, limit).map(
                                    (client) => (
                                        <tr
                                            key={client._id}
                                            data-clickable
                                            onClick={handleNavigate(client._id)}
                                        >
                                            <td>{client.corporateName}</td>
                                            <td>
                                                {String(client.mail) !== 'NaN'
                                                    ? client.mail
                                                    : 'N/A'}
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </Table>
                    <TablePagination
                        labelRowsPerPage="Itens por página"
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={clients.length}
                        rowsPerPage={limit}
                        labelDisplayedRows={({ from, to, count }) =>
                            from ? `${from}-${to} de ${count}` : `1 de ${count}`
                        }
                        page={Number(page - 1)}
                        onPageChange={(_, page) => setPage(page + 1)}
                        onRowsPerPageChange={(e) => {
                            setLimit(Number(e.target.value))
                        }}
                    />
                </>
            )}
        </Container>
    )
}
